import classnames from 'classnames'
import React from 'react'

function PopoutButton(
  { onClick, children, className, href, alert, info, leftAlign },
  ref
) {
  const handleClick = (e) => {
    e.stopPropagation()

    if (onClick) {
      onClick()
    }
  }

  const buttonProps = {
    className: classnames(
      `rounded-xl hover:bg-alt-bg p-2 w-full cursor-pointer select-none focus:outline-none items-center flex flex-row justify-center`,
      className,
      { 'text-topic-blue bg-topic-blue-20': info },
      { 'text-alert': alert },
      { 'text-center': !leftAlign },
      { 'text-left': leftAlign }
    ),
    onClick: handleClick,
    href,
    ref,
  }

  if (href) {
    return <a {...buttonProps}>{children}</a>
  }

  return <button {...buttonProps}>{children}</button>
}

export default React.forwardRef(PopoutButton)
